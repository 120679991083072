import * as React from "react";
import * as LabelPrimitive from "@radix-ui/react-label";
import { Slot } from "@radix-ui/react-slot";

import { cn } from "~/lib/utils";
import { Label } from "~/components/ui/label";
import { Fieldset, FormConfig, conform, useForm } from "@conform-to/react";
import invariant from "tiny-invariant";

const ConformContext = React.createContext<{
  form: ReturnType<typeof useForm>[0];
  fieldset: ReturnType<typeof useForm>[1];
}>({} as any);

const useConformForm = <
  Output extends Record<string, any>,
  Input extends Record<string, any> = Output
>(
  options: FormConfig<Output, Input>
) => {
  const [form, fieldset] = useForm<Output, Input>(options);

  // const FormContext = ({ children }: { children: React.ReactNode }) => (
  //   <ConformContext.Provider value={{ form, fieldset }}>
  //     <Slot {...form.props} children={children} />
  //   </ConformContext.Provider>
  // );

  return {
    FormContext: WorkaroundContext,
    FormItem: FormItem<keyof Fieldset<Output>>,
    fieldset,
    form,
    context: {
      form,
      fieldset,
    },
  } as const;
};

export function WorkaroundContext({
  context,
  children,
}: {
  context: any;
  children: React.ReactNode;
}) {
  return (
    <ConformContext.Provider value={context}>
      <Slot {...context.form.props} children={children} />
    </ConformContext.Provider>
  );
}

const FormItemContext = React.createContext<{
  id?: string;
  name: string;
  field: Fieldset<any>[string];
}>({} as any);

const useField = (name?: string) => {
  const { fieldset } = React.useContext(ConformContext);
  const { field } = React.useContext(FormItemContext);

  if (name) return fieldset[name];
  if (field) return field;

  throw new Error("Need to specify a name or wrap in a FormItem");
};

const useFormField = useField;

type ConformMethodTypes = "input" | "select" | "textarea" | "fieldset";

export const useFieldProps = (
  type: ConformMethodTypes = "input",
  opts?: any
) => {
  const data = useFormField();

  if (!data) return {};

  switch (type) {
    case "input":
      return conform.input(data, opts);
    case "select":
      return conform.select(data);
    case "textarea":
      return conform.textarea(data);
    case "fieldset":
      return conform.fieldset(data);
  }
};

function FormItem<T extends string>({
  name,
  children,
  ...props
}: {
  name: T;
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLFieldSetElement>) {
  const { fieldset } = React.useContext(ConformContext);

  const field = fieldset[name];
  invariant(field, `Field ${name} not found`);

  return (
    <FormItemContext.Provider value={{ name, field }}>
      <fieldset {...props}>{children}</fieldset>
    </FormItemContext.Provider>
  );
}

const FormLabel = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>
>(({ className, ...props }, ref) => {
  const { error, id } = useFormField();

  return (
    <Label
      ref={ref}
      className={cn("mb-4", error && "text-red-500", className)}
      htmlFor={id}
      {...props}
    />
  );
});
FormLabel.displayName = "FormLabel";

const FormControl = React.forwardRef<
  React.ElementRef<typeof Slot>,
  React.ComponentPropsWithoutRef<typeof Slot>
>(({ ...props }, ref) => {
  const { error } =
    useFormField();

  return (
    <Slot
      ref={ref}
      // id={formItemId}
      // aria-describedby={
      //   !error
      //     ? `${formDescriptionId}`
      //     : `${formDescriptionId} ${formMessageId}`
      // }
      aria-invalid={!!error}
      {...props}
    />
  );
});
FormControl.displayName = "FormControl";

const FormDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => {
  // const { formDescriptionId } = useFormField();

  return (
    <p
      ref={ref}
      // id={formDescriptionId}
      className={cn("text-sm text-slate-500 dark:text-slate-400", className)}
      {...props}
    />
  );
});
FormDescription.displayName = "FormDescription";

const FormMessage = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, children, ...props }, ref) => {
  const { error } = useFormField();
  const body = error ? String(error) : children;

  if (!body) {
    return null;
  }

  return (
    <p
      ref={ref}
      className={cn("text-sm mt-1 font-medium text-red-500 ", className)}
      {...props}
    >
      {body}
    </p>
  );
});
FormMessage.displayName = "FormMessage";

export {
  useConformForm,
  useFormField,
  FormItem,
  FormLabel,
  FormControl,
  FormDescription,
  FormMessage,
  // FormField,
};
